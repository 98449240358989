@import "~bootstrap/scss/bootstrap";

$bootstrap-icons-font-dir: "~bootstrap-icons/font/fonts";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }

.demo-arrow {
  margin-right: 20px;
  vertical-align: -0.50em;
  font-size: 20px;  
  color: grey;
  &--lg {
    font-size: 40px; 
  }
}

.demo-task {
  margin-right: 20px;
  vertical-align: -0.45em;
  font-size: 40px;  
  color: grey;
  &--success {
    color: green;
  }
  &--failed {
    color: red;
  }
}

.demo-result {
  @extend .h4;
  color: grey;
  margin-top: 0.4rem;
}

html {
  scroll-behavior: smooth;
}

pre {
  white-space: pre-wrap;
}

// table, th, td {
//   border: 1px solid black;
// }
